.slick-slider {
  padding-block: 0;
  .slick-slide {
    padding: 0 15px;

    /* padding: 1vh 5px; */
    /* margin:2vh 2vw; */
  }
}

.slick-slider-client {
  padding-block: 10px;
  align-items: baseline;
  .slick-slide {
    padding: 0 15px;

    /* padding: 1vh 5px; */
    /* margin:2vh 2vw; */
  }
}
