@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

body {
  margin: 0;
  background-color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "F37-Regular";
  src: url("./assets/fonts/Montserrat-regular.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Planet-Apes";
  src: url("./assets/fonts/planetoftheapes.ttf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-regular.ttf");
}

:root {
  --font-p_m: "Poppins-Medium";
  --font-f_r: "F37-Regular";
  --font-p_r: "Poppins-Regular";
  --font-i_r: "Inter-Regular";
  --font-i_p: "Planet-Apes";
  --font-m_r: "Montserrat-Regular";
}

html[dir="rtl"] {
  --font-p_r: "Tajawal", sans-serif;
}

.MuiDataGrid-virtualScroller {
  height: 46vh !important;
}

.slick-slidd {
  padding-block: 0px;
  .slick-slide {
    padding: 0px;
  }
  .slick-slide {
    padding: 0px;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 6px;
  border-radius: 16px;
}
::-webkit-scrollbar-thumb {
  background-color: #dece82;
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  border-radius: 16px;
  background-color: transparent;
}
