.snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 16px;
    /* position: fixed; */
    z-index: 1;
    /* right: 0;
    bottom: 30px; */
    /* transform: translateX(-50%); */
    margin-top: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.5s, bottom 0.5s;
}

.snackbar.show {
    visibility: visible;
    opacity: 1;
    bottom: 50px;
}
