/* Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it's on top of other content */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle,
.circle::before {
  content: " ";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: #eabe59;
}

.circle::before {
  animation: mymove 1.5s infinite;
  position: absolute;
  background-color: #00ff00;
}

@keyframes mymove {
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
